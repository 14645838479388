import Resources from '../Resources/components';
import CustomerHelperScripts from './customer-helper-scripts';

/**
 * Customer Helper Loader
 * This loads the correct class based on the domain for custom scripts
 */
export default class CustomerHelperLoader {
    static helper = {};

    /**
     * Load scripts from resources
     */
    static async loadScripts() {
        const classObject = { default: null };
        classObject.default = function () {};

        // Find in resources
        try {
            const customerScriptsResource = await Resources.asyncGetOrLoad('customerScripts');
            if (customerScriptsResource) {
                classObject.default = CustomerHelperScripts;
                classObject.default.loadFunctions(customerScriptsResource);
                CustomerHelperLoader.helper = classObject.default;
            }
        } catch (e) {}
    }

    /**
     * Load domain specific helper
     * @param {} domain
     */
    static async load(domain) {
        let classObject = { default: null };
        classObject.default = function () {};

        // Find in folder
        if (domain === 'klm') {
            classObject = await import('customer-functions/klm/helpers.js');
        } else if (domain === 'airfrance') {
            classObject = await import('customer-functions/airfrance/helpers.js');
        } else if (domain === 'takeaway') {
            classObject = await import('customer-functions/takeaway/helpers.js');
        } else if (domain === 'basicfit') {
            classObject = await import('customer-functions/basicfit/helpers.js');
        } else if (domain === 'dpgmedia') {
            classObject = await import('customer-functions/dpgmedia/helpers.js');
        } else if (domain === 'era') {
            classObject = await import('customer-functions/era/helpers.js');
        } else if (domain === 'essent') {
            classObject = await import('customer-functions/essent/helpers.js');
        } else if (domain === 'kpn') {
            classObject = await import('customer-functions/kpn/helpers.js');
        } else if (domain === 'nn') {
            classObject = await import('customer-functions/nn/helpers.js');
        } else if (domain === 'proximus' || domain === 'scarlet') {
            classObject = await import('customer-functions/proximus/helpers.js');
        } else if (domain === 'switch') {
            classObject = await import('customer-functions/switch/helpers.js');
        } else if (domain === 'vriendenloterij') {
            classObject = await import('customer-functions/vriendenloterij/helpers.js');
        } else if (domain === 'wehkamp') {
            classObject = await import('customer-functions/wehkamp/helpers.js');
        } else if (domain === 'rituals') {
            classObject = await import('customer-functions/rituals/helpers.js');
        } else if (domain === 'engagement') {
            classObject = await import('customer-functions/engagement/helpers.js');
        } else if (domain === 'anwb') {
            classObject = await import('customer-functions/anwb/helpers.js');
        } else if (domain === 'ing') {
            classObject = await import('customer-functions/ing/helpers.js');
        } else if (domain === 'partou') {
            classObject = await import('customer-functions/partou/helpers.js');
        } else if (domain === 'leenbakker') {
            classObject = await import('customer-functions/leenbakker/helpers.js');
        } else if (domain === 'picnic') {
            classObject = await import('customer-functions/picnic/helpers.js');
        } else if (domain === 'vandebron') {
            classObject = await import('customer-functions/vandebron/helpers.js');
        } else if (domain === 'streamz') {
            classObject = await import('customer-functions/streamz/helpers.js');
        } else if (domain === 'havas') {
            classObject = await import('customer-functions/havas/helpers.js');
        } else if (domain === 'npl') {
            classObject = await import('customer-functions/npl/helpers.js');
        } else if (domain === 'hogarth') {
            classObject = await import('customer-functions/hogarth/helpers.js');
        } else if (domain === 'demo') {
            classObject = await import('customer-functions/demo/helpers.js');
        } else if (domain === 'iryo') {
            classObject = await import('customer-functions/iryo/helpers.js');
        } else if (domain === 'delta') {
            classObject = await import('customer-functions/delta/helpers.js');
        } else if (domain === 'tempoteam') {
            classObject = await import('customer-functions/tempoteam/helpers.js');
        } else if (domain === 'jde') {
            classObject = await import('customer-functions/jde/helpers.js');
        } else if (domain === 'philips') {
            classObject = await import('customer-functions/philips/helpers.js');
        } else if (domain === 'vwdealeritaly') {
            classObject = await import('customer-functions/vw_dealer_italy/helpers.js');
        } else if (domain === 'entain') {
            classObject = await import('customer-functions/entain/helpers.js');
        } else if (domain === 'aldi' || domain === 'aldi-suisse') {
            classObject = await import('customer-functions/aldi/helpers.js');
        } else if (domain === 'kwantum') {
            classObject = await import('customer-functions/kwantum/helpers.js');
        } else if (domain === 'eurowings') {
            classObject = await import('customer-functions/eurowings/helpers.js');
        } else if (domain === 'abnamro') {
            classObject = await import('customer-functions/abnamro/helpers.js');
        } else if (domain === 'peoplespostcodelottery') {
            classObject = await import('customer-functions/peoplespostcodelottery/helpers.js');
        } else if (domain === 'nordvik') {
            classObject = await import('customer-functions/nordvik/helpers.js');
        } else if (domain === 'onecasino') {
            classObject = await import('customer-functions/onecasino/helpers.js');
        } else if (domain === 'hollandcasino') {
            classObject = await import('customer-functions/hollandcasino/helpers.js');
        }
        CustomerHelperLoader.helper = classObject.default;
    }
}
